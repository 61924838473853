import { Button, CircularProgress } from '@mui/material';
import React from 'react';

// <--------- Input Field --------->
export function InputField({ register, errors, name, pattern, placeholder, type }) {
    return (
        <div className='mb-5'>
            <input
                type={type ? type : 'text'}
                placeholder={placeholder}
                className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                {...register(name, pattern)}
                error={Boolean(errors[name])}
            />
            {
                errors[name] && <div className='text-[11px] text-red-400 mt-1'>{errors[name]?.message}</div>
            }
        </div>
    )
}

// <--------- Label --------->
export function Label({ text }) {
    return (
        <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px]'>{text}</div>
    )
}

// <--------- Date Field --------->
export function DateField({ register, errors, name, pattern, handleDateInput, dateValue }) {
    return (
        <div className='mb-5'>
            <div
                style={{ color: dateValue === '' && '#9ca3af' }}
                className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10 custom-date-picker relative flex items-center justify-between'>
                {dateValue ? dateValue : 'Select Date'}
                <img src='./assets/icons/calender.svg' />
                <input
                    type='date'
                    {...register(name, pattern)}
                    error={Boolean(errors[name])}
                    onChange={handleDateInput}
                />
            </div>
            {
                errors[name] && <div className='text-[11px] text-red-400 mt-1'>{errors[name]?.message}</div>
            }
        </div>
    )
}

// <--------- Text Area --------->
export function TextArea({ register, errors, name, pattern, placeholder, rows }) {
    return (
        <div>
            <textarea
                rows={rows}
                placeholder={placeholder}
                className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 flex justify-center items-center py-3'
                {...register(name, pattern)}
                error={Boolean(errors[name])}
            />
            {
                errors[name] && <div className='text-[11px] text-red-400 mt-1'>{errors[name]?.message}</div>
            }
        </div>
    )
}

// <--------- Button --------->
export function CustomButton({ text, isLoading }) {
    return (
        <Button
            type='submit'
            className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] hover:bg-hover-button text-sm rounded-lg w-full mt-6 mb-16 shadow-lg capitalize'>
            {isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : text}
        </Button>
    )
}

