import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { HOME_POPUP } from '../constant/constant';
import Cookies from 'js-cookie';

export default function Popup({ setIsPopupOpen }) {
    useEffect(() => {
        document.querySelector('body').classList.add('scroll-body');
        return () => document.querySelector('body').classList.remove('scroll-body');
    }, [])

    // <--------- Handle home popup --------->
    function handlePopup() {
        Cookies.set(HOME_POPUP, false);
        setIsPopupOpen(false);
    }

    return (
        <div className='h-screen backdrop-blur-sm bg-[#51515192] w-full absolute flex justify-center items-center top-0 right-0 z-50'>
            <div className='bg-white rounded-xl h-[500px] sm:h-[450px] w-[450px] mx-2 p-8 font-inter flex flex-col justify-between font-light dark:text-white text-sm dark:bg-custom-bg'>
                <div className='flex justify-center items-center'>
                    <img className='h-12 w-12' src='./assets/icons/logo.svg' />
                    <p className='ml-2 text-base font-bold'>BMA</p>
                </div>
                <p><span className='font-medium'>Exciting news!</span> Our Boat Maintenance App is currently in a testing phase, and that means it's FREE for you to use. We're fine-tuning things and gathering feedback.</p>
                <p>While it's free now, please note that in the future, we'll be introducing a paid version. Expect daily updates as we're dedicated to making this app top-notch and simple to use.</p>
                <p className='font-medium'>Thank you for being part of our Boat Maintenance App journey.</p>
                <p className='font-medium'>The Boat Maintenance App Team</p>
                <div>
                    <Button className='text-white font-medium font-inter tracking-wider h-9 bg-[#0E9CEC] hover:bg-hover-button text-sm rounded-lg w-full shadow-lg normal-case' onClick={handlePopup}>Ok</Button>
                </div>
            </div>
        </div>
    )
}
