import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';

export default function ScheduleMaintenance() {
  const navigate = useNavigate();
  return (
    <div className='min-h-screen dark:bg-custom-bg pb-4'>
      <Navbar imgIconSrc={'/./assets/icons/services/time.svg'} text={'Schedule Maintenance'} />
      <div className='mx-4 pt-16'>

        <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-8'>Endeavor</div>
        <div className='flex justify-end'>
          <Button className='flex items-center bg-custom-blue rounded-md px-2 py-1' onClick={() => navigate('/addMaintenance')}>
            <PlusIcon className='h-4 w-4 text-white' strokeWidth={2} />
            <div className='text-white ml-1 font-inter text-[13px] normal-case'>Add new schedule</div>
          </Button>
        </div>

        <div className='flex h-28 rounded-lg dark:text-white dark:bg-[#253444] bg-[#EEEEEE] overflow-hidden pr-4 mt-3'>
          <div className='w-[0.4rem] mr-3 bg-maintenance'></div>
          <div className='flex flex-col justify-between w-full'>
            <div className='w-full'>
              <div className='flex justify-between text-[14px] font-medium mt-2 mb-1'>
                <span>Motor</span>
                <span>24JAN24</span>
              </div>
              <p className='text-[12px] dark:font-light'>Port motor is not starting. There is some leakage coming from the oil filter case...</p>
            </div>
            <div className='flex justify-end'>
              <button className='bg-custom-yellow rounded-xl text-[13px] py-[0.2rem] font-medium w-24 mb-2 text-white'>In process</button>
            </div>
          </div>
        </div>

        <div className='flex h-28 rounded-lg dark:text-white dark:bg-[#253444] bg-[#EEEEEE] overflow-hidden pr-4 mt-3'>
          <div className='w-[0.4rem] mr-3 bg-maintenance'></div>
          <div className='flex flex-col justify-between w-full'>
            <div className='w-full'>
              <div className='flex justify-between text-[14px] font-medium mt-2 mb-1'>
                <span>Motor</span>
                <span>24JAN24</span>
              </div>
              <p className='text-[12px] dark:font-light'>Anchor motor is not operating.</p>
            </div>
            <div className='flex justify-end'>
              <button className='bg-custom-orange rounded-xl text-[13px] py-[0.2rem] font-medium w-24 mb-2 text-white'>Pending</button>
            </div>
          </div>
        </div>

        <div className='flex h-28 rounded-lg dark:text-white dark:bg-[#253444] bg-[#EEEEEE] overflow-hidden pr-4 mt-3'>
          <div className='w-[0.4rem] mr-3 bg-maintenance'></div>
          <div className='flex flex-col justify-between w-full'>
            <div className='w-full'>
              <div className='flex justify-between text-[14px] font-medium mt-2 mb-1'>
                <span>Motor</span>
                <span>24JAN24</span>
              </div>
              <p className='text-[12px] dark:font-light'>Radar turns on but is not turning.</p>
            </div>
            <div className='flex justify-end'>
              <button className='bg-custom-green rounded-xl text-[13px] py-[0.2rem] font-medium w-24 mb-2 text-white'>Completed</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
