import React from 'react';
import Navbar from '../Navbar';
import ScheduleNewMaintenanceForm from '../forms/ScheduleNewMaintenanceForm';

export default function ScheduleNewMaintenance() {
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/services/time.svg'} text={'Schedule New Maintenance'} />
            <div className='mx-4 pt-16'>
                <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-4'>Endeavor</div>
                <ScheduleNewMaintenanceForm />
            </div>
        </div>
    )
}
