import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { languageList } from '../../constant/constant';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { userRegistrationApi } from '../../rest/AuthApi';

export default function SignupFormNew() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isDropDown, setIsDropDown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const [currentLang, setCurrentLang] = useState({
        imgSrc: '/./assets/icons/flags/uk.svg',
        text: 'English'
    });

    // <--------- Close menu when clicked outside --------->
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsDropDown(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        setIsLoading(true);
        const result = await userRegistrationApi(data);
        console.log(result)
        if (result?.status === 201) {
            enqueueSnackbar('Confirmation email sent', { variant: 'success' });
            navigate('/verification/email/page');
        }
        else if (result?.data?.code === 40911)
            enqueueSnackbar("Email already exists", { variant: 'error' });
        else
            enqueueSnackbar('Something went wrong', { variant: 'error' })
        setIsLoading(false);
    }

    // <--------- Language dropdown options --------->
    const dropDown = (
        <div ref={menuRef} style={{ boxShadow: '0 0 5px 2px rgba(0,0,0,0.1)' }} className='bg-white rounded-lg text-sm w-full outline-none border dark:border-0 border-gray-300 bottom-11 left-0 absolute z-10 cursor-pointer overflow-hidden'>
            {
                languageList.map((item, index) => {
                    return (
                        <div key={index} className='flex items-center py-2 hover:bg-gray-200 px-4' onClick={() => setCurrentLang(item)}>
                            <img
                                src={item.imgSrc}
                                className='mr-3 h-6 w-6 object-cover rounded-full shadow-lg'
                                loading='lazy'
                            />
                            <span className='flex-1 text-gray-700 font-inter'>{item.text}</span>
                        </div>
                    )
                })
            }
        </div>
    )

    return (
        <form className='w-full mt-12' onSubmit={handleSubmit(onSubmit)}>
            <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px]'>Name</div>
            <div>
                <input
                    placeholder='Name'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300  h-10'
                    {...register('name', { required: 'Field required*' })}
                    error={Boolean(errors.name)}
                />
                {
                    errors.name && <div className='text-[11px] text-red-400 mt-1'>{errors.name?.message}</div>
                }
            </div>
            <div>
                <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px] mt-5'>Boat Name</div>
                <input
                    placeholder='Boat name'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300  h-10'
                    {...register('boatName')}
                    error={Boolean(errors.boatName)}
                />
                {
                    errors.boatName && <div className='text-[11px] text-red-400 mt-1'>{errors.boatName?.message}</div>
                }
            </div>
            <div>
                <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px] mt-5 '>Email</div>
                <input
                    placeholder='Email address'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300  h-10'
                    {...register("email", {
                        required: "Field required*",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    })}
                    error={Boolean(errors.email)}
                />
                {
                    errors.email && <div className='text-[11px] text-red-400 mt-1'>{errors.email?.message}</div>
                }
            </div>


            <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px] mt-5'>Language</div>
            <div ref={containerRef} className='flex items-center bg-white rounded-lg text-sm pl-4 pr-2 w-full outline-none border dark:border-0 border-gray-300  h-10 relative cursor-pointer' onClick={() => setIsDropDown(!isDropDown)}>
                <img
                    src={currentLang.imgSrc}
                    className='mr-3 h-6 w-6 object-cover rounded-full shadow-lg'
                />
                <span className='flex-1 text-gray-700 font-inter'>{currentLang.text}</span>
                <ChevronDownIcon className='h-6 w-6 text-gray-500' />
                {isDropDown && dropDown}
            </div>

            <Button variant='contained' className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] hover:bg-hover-button rounded-lg w-full mt-8 shadow-lg capitalize' type='submit'>{isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : "Create Account"}</Button>
        </form>
    )
}
