import React, { useEffect, useRef, useState } from 'react';
import { ThemeSwitch } from './Helper';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { appTheme, isAuthenticated, appLayout } from '../recoil/store';
import { Switch } from '@mui/material';
import store from 'store';
import { ArrowLeftStartOnRectangleIcon, ArrowUturnUpIcon, ChevronLeftIcon, Cog8ToothIcon, InformationCircleIcon, LanguageIcon, MagnifyingGlassIcon, PencilIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { ACCESS_TOKEN, REFRESH_TOKEN, SESSION_ID, downloadOrOpenApp, isMobile } from '../constant/constant';
import { useNavigate } from 'react-router-dom';
import { logoutApi } from '../rest/AuthApi';
import { enqueueSnackbar } from 'notistack';

export default function Navbar({ imgIconSrc, text, home }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = useRecoilState(appTheme);
  const setIsLoggedIn = useSetRecoilState(isAuthenticated);
  const [selectedAppLayout, setSelectedAppLayout] = useRecoilState(appLayout);
  const isMobileScreen = useMediaQuery({ maxWidth: 640 });
  const containerRef = useRef();
  const menuRef = useRef();
  const navigate = useNavigate();

  // <--------- Close menu when clicked outside --------->
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // <--------- Handle theme --------->
  const handleThemeChange = (event) => {
    if (event.target.checked === true)
      setTheme('dark');
    else
      setTheme('light');
  };

  // <--------- Handle layout --------->
  const handleLayoutChange = (event) => {
    if (event.target.checked === true)
      setSelectedAppLayout('list');
    else
      setSelectedAppLayout('grid');
  };

  // <--------- Logout Function --------->
  async function logoutFun() {
    const result = await logoutApi();
    console.log(result);
    if (result?.status === 202) {
      store.remove(SESSION_ID);
      setIsLoggedIn(false);
    }
    else
      enqueueSnackbar('Something went wrong', { variant: 'error' });
  }

  // <--------- Menu Options --------->
  const menuOption = (
    <div ref={menuRef} className=' rounded-xl absolute bg-white dark:bg-[#bed6e4c4] text-sm right-0 sm:left-0 top-8 px-4 py-2 w-28 z-10 shadow-custom'>
      <div className='h-8 flex items-center' >
        <span>{theme === 'dark' ? 'Dark' : 'Light'}</span>
        <span><ThemeSwitch checked={theme === 'dark' ? true : false} onChange={handleThemeChange} /></span>
      </div>
      <div className='h-8 flex items-center' >
        <span>Layout</span>
        <span><Switch checked={selectedAppLayout === 'list' ? true : false} onChange={handleLayoutChange} /></span>
      </div>
      <div className='h-8 flex items-center text-red-700 cursor-pointer' onClick={logoutFun}>LogOut</div>
    </div>
  )

  // <--------- Menu Options --------->
  const menuOptions = (
    <div ref={menuRef} className=' rounded-lg absolute bg-white dark:bg-[#253444] text-sm right-0 sm:left-0 top-8 z-10 outline-none border dark:border-0 border-gray-300 overflow-hidden shadow-custom dark:text-white w-36'>

      <div
        className='flex items-center text-sm px-4 border-b-[1px] dark:border-white border-gray-300 h-10 cursor-pointer'
        onClick={() => navigate('/invite')}>
        <UsersIcon className='h-5 mr-2 ' strokeWidth={2} />
        <span>Invite Others</span>
      </div>

      {
        isMobile() &&
        <div
          className='flex items-center text-sm px-4 border-b-[1px] dark:border-white border-gray-300 h-10 cursor-pointer'
          onClick={() => downloadOrOpenApp(null)}>
          <ArrowUturnUpIcon className='h-5 mr-2' strokeWidth={2} />
          <span>Open In App</span>
        </div>
      }

      <div
        className='flex items-center text-sm px-4 border-b-[1px] dark:border-white border-gray-300 h-10 cursor-pointer'
        onClick={() => navigate('/customise')}>
        <PencilIcon className='h-5 mr-2 ' strokeWidth={2} />
        <span>Customise</span>
      </div>

      <div
        className='flex items-center text-sm px-4 border-b-[1px] dark:border-white border-gray-300 h-10 cursor-pointer'
        onClick={() => navigate('/language')}>
        <LanguageIcon className='h-5 mr-2 ' strokeWidth={2} />
        <span>Languages</span>
      </div>

      <div
        className='flex items-center text-sm px-4 border-b-[1px] dark:border-white border-gray-300 h-10 cursor-pointer'
        onClick={() => navigate('/version')}>
        <InformationCircleIcon className='h-5 mr-2 ' strokeWidth={2} />
        <span>Version</span>
      </div>

      <div
        className='flex items-center text-sm px-4 h-10 cursor-pointer'
        onClick={logoutFun}>
        <ArrowLeftStartOnRectangleIcon className='h-5 mr-2 rotate-180' strokeWidth={2} />
        <span>Logout</span>
      </div>

    </div>
  )

  // <--------- Mobile Navbar --------->
  const mobileNavbar = (
    <div className='fixed w-full z-20'>
      <div className='flex justify-between items-center pt-3 pb-2 px-4 dark:bg-custom-bg bg-white w-full'>
        <div className='flex items-center'>
          {
            home ? (
              <img className='h-7 w-7 mr-2' src='/./assets/icons/logo.svg' />
            ) : (
              <>
                <ChevronLeftIcon className='h-6 w-6 dark:text-white' strokeWidth={2} onClick={() => navigate(-1)} />
                <img className='h-6 w-6 ml-3 mr-2' src={imgIconSrc} />
              </>
            )
          }
          <div className='dark:text-white font-bold font-inter text-sm tracking-wider'>{text}</div>
        </div>
        <div ref={containerRef} className='relative flex items-center'>
          {!home && <MagnifyingGlassIcon className='h-5 dark:text-white mr-3' />}
          <div className='w-7 h-7 dark:text-white' onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {<Bars3Icon />}
          </div>
          {isMenuOpen && menuOptions}
        </div>

      </div>
      {/* {home && <div className='border-t dark:border-white border-gray-400 mb-4'></div>} */}
    </div>
  )

  // <--------- Tablet/Desktop Navbar --------->
  const desktopNavbar = (
    <div className='flex justify-between items-center px-[8vw] shadow-md py-[0.8rem] dark:text-white'>
      <div className='flex items-center'>
        <img className='h-6 w-6' src='/./assets/icons/logo.svg' />
        <span className='ml-3 font-inter font-bold tracking-wide text-sm'>BMA</span>
      </div>
      <div className='font-medium flex'>
        {/* <span className='mr-16 text-sm hover:text-custom-blue cursor-pointer'>Signup</span> */}
        {/* <span className='text-sm hover:text-custom-blue cursor-pointer'>Login</span> */}
        <div ref={containerRef} className='ml-6 relative' >
          <Cog8ToothIcon className='cursor-pointer dark:text-white h-6 w-6' onClick={() => setIsMenuOpen(!isMenuOpen)} />
          {isMenuOpen && menuOption}
        </div>
      </div>
    </div>
  )

  return (
    isMobileScreen ? mobileNavbar : desktopNavbar
  )
}
