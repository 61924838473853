import React, { useEffect } from 'react'
import Loader from './Loader'
import { PLAYSTORE_URL, isMobile } from '../constant/constant'
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export default function BmaApp() {
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (isMobile()) {
    //         window.location.href = PLAYSTORE_URL;
    //     }
    // }, [])

    const loginSuccessJsxMobile = (
        <div className="dark:bg-custom-bg min-h-screen flex justify-center items-center relative">
            <div className="flex flex-col items-center w-[300px] sm:w-[350px] mx-4">
                <img
                    src='/./assets/icons/logo.svg'
                    className='h-[60px] w-[60px] mb-5'
                />
                <div className='dark:text-white text-custom-bg font-bold font-inter tracking-wide'>BMA</div>

                {/* <div
                    className='text-center dark:text-white text-custom-bg font-bold font-inter text-xl tracking-wider my-8'>
                    Login Successful
                </div> */}

                <div className='mx-4 w-full'>
                    <div className='dark:text-white text-custom-bg text-center text-sm font-light leading-[24px]'>
                        Welcome to BMA. To continue, please close this browser window and open the BMA APP directly from your mobile and enjoy all the features on the go (recommended).
                    </div>
                    <Button
                        className='text-white font-medium  tracking-wide h-10 bg-[#0E9CEC] text-sm rounded-lg mt-6 shadow-lg capitalize w-full border border-red-700'
                        onClick={() => window.location.href = PLAYSTORE_URL}>
                        Donwload Bma App
                    </Button>
                    <div
                        className='text-gray-400 dark:text-gray-300 text-center font-extralight text-xs leading-[24px] mt-7 mb-5'>
                        If you wish to use our app via the web, click the button below to use our web version.
                    </div>

                    <div
                        className='text-[#0E9CEC] text-center font-light underline cursor-pointer'
                        onClick={() => navigate('/')}>
                        BMA Web Version
                    </div>
                </div>
            </div>
        </div>
    )

    const loginSuccessJsxWeb = (
        <div className="dark:bg-custom-bg min-h-screen flex justify-center items-center relative">
            <div className="flex flex-col items-center w-[300px] sm:w-[350px] mx-4">
                <img
                    src='/./assets/icons/logo.svg'
                    className='h-[60px] w-[60px] mb-5'
                />
                <div className='dark:text-white text-custom-bg font-bold font-inter tracking-wide'>BMA</div>

                {/* <div
                    className='text-center dark:text-white text-custom-bg font-bold font-inter text-xl tracking-wider my-8'>
                    Login Successful
                </div> */}

                <div className='mx-4 w-full'>
                    <div className='dark:text-white text-custom-bg text-center text-sm font-light leading-[24px]'>
                        Welcome to BMA. To continue, please close this browser window and open the BMA APP directly from your mobile and enjoy all the features on the go (recommended).
                    </div>
                    {/* <Button
                        className='text-white font-medium  tracking-wide h-10 bg-[#0E9CEC] text-sm rounded-lg mt-6 shadow-lg capitalize w-full border border-red-700'
                        onClick={() => window.location.href = PLAYSTORE_URL}>
                        Donwload Bma App
                    </Button> */}
                    <div
                        className='text-gray-400 dark:text-gray-300 text-center font-extralight text-xs leading-[24px] mt-7 mb-5'>
                        If you wish to use our app via the web, click the button below to use our web version.
                    </div>

                    <div
                        className='text-[#0E9CEC] text-center font-light underline cursor-pointer'
                        onClick={() => navigate('/')}>
                        BMA Web Version
                    </div>
                </div>
            </div>
        </div>
    )
    return (
        <div className='h-screen'>
            {isMobile() ? loginSuccessJsxMobile : loginSuccessJsxWeb}
        </div>
    )
}
