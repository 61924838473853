import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import i18n from './language/i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider autoHideDuration={3000} anchorOrigin={{
          vertical: 'top', horizontal: 'right'
        }}>
          <App />
        </SnackbarProvider>
      </I18nextProvider>
    </RecoilRoot>
  </BrowserRouter>
);
