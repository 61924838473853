import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import store from 'store';
import { useRecoilState } from 'recoil';
import { DEVICE_ID, SESSION_ID, isMobile } from '../../constant/constant';
import { emailVerificationApi } from '../../rest/AuthApi';
import { isAuthenticated } from '../../recoil/store';
import Loader from '../../components/Loader'
export default function Verification() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const [isLoading, setIsLoading] = useState(true);
    const [auth, setIsAuth] = useRecoilState(isAuthenticated);
    async function emailVerification() {
        setIsLoading(true);
        const result = await emailVerificationApi(token);
        if (result?.status === 200) {
            const deviceId = store.get(DEVICE_ID)
            if (result?.data?.deviceId === deviceId) {
                store.set(SESSION_ID, result?.data?.sessionId);
                setIsAuth(true);
                navigate('/');
            }
            else {
                if (isMobile()) {
                    window.location.href = `https://bma.ifalcon.net/app/Splash?deviceId=${result?.data?.deviceId}`;
                }
                else {
                    store.remove(SESSION_ID);
                    setIsAuth(false);
                    navigate('/app');
                }

            }
        }
        else if (result?.data?.code === 40425) {
            enqueueSnackbar('Link is invalid', { variant: 'error' });
            setIsAuth(false);
            store.remove(SESSION_ID);
            navigate('/');
        }
        else if (result?.data?.code === 40825) {
            enqueueSnackbar('Link is expired', { variant: 'error' });
            setIsAuth(false);
            store.remove(SESSION_ID);
            navigate('/');
        }
        else {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
            navigate('/');
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (token)
            emailVerification();
        else
            navigate(-1);
    }, []);

    return (
        <div className='h-screen'>
            <Loader />
        </div>
    )
}
