import { userInstance } from "./AxiosInstance";

// <--------- User Details --------->
export const getuserDetailsApi = async () => {
    try {
        const result = await userInstance.get('/me');
        return result;
    } catch (error) {
        return error?.response;
    }
}

