import { Button } from '@mui/material';
import React from 'react';
import { services } from '../../../constant/constant';
import { appLayout } from '../../../recoil/store';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';


export default function HomeMobile() {
    const navigate = useNavigate();
    const selectedLayout = useRecoilValue(appLayout);

    // <--------- List View --------->
    const listView = (
        <div className='flex flex-col gap-3'>
            {
                services?.map((item, index) => {
                    return (
                        <Button className={'flex justify-start gap-3 normal-case items-center dark:bg-[#253444] rounded-lg relative shadow-custom w-full h-20'} key={index} onClick={() => navigate(item.url)}>
                            <img src={item?.imgSrc} className='h-12 w-12' />
                            <div className='dark:text-white text-black font-inter font-medium tracking-wide'>{item?.text}</div>
                            <div style={{ background: item.notificationColor }} className={'flex items-center justify-center rounded-full absolute top-1 right-1 h-4 w-4'}>
                                <div className='text-gray-700 font-inter text-[12px]'>1</div>
                            </div>
                        </Button>
                    )
                })
            }
        </div>
    )

    // <--------- Grid View --------->
    const gridView = (
        <div className='grid grid-cols-2 gap-3'>
            {
                services?.map((item, index) => {
                    return (
                        <Button className={'flex flex-col normal-case items-start dark:bg-[#253444] rounded-lg relative shadow-custom h-28'} key={index} onClick={() => navigate(item.url)}>
                            <img src={item?.imgSrc} className='h-10 w-10' />
                            <div className='dark:text-white text-black font-inter mt-2 font-medium tracking-wide'>{item?.text}</div>
                            <div style={{ background: item.notificationColor }} className={'flex items-center justify-center rounded-full absolute top-1 right-1 h-4 w-4'}>
                                <div className='text-gray-700 font-inter text-[12px]'>1</div>
                            </div>
                        </Button>
                    )
                })
            }
        </div>
    )

    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <div className='mx-4 pt-16'>
                <div className='flex items-center justify-between'>
                    <div className='dark:text-white font-inter text-lg font-bold'>Endeavor</div>
                    <Button className='flex items-center bg-custom-blue rounded-md px-3 py-1'>
                        <img src='./assets/icons/edit2.svg' className='h-3 w-3' />
                        <div className='text-white ml-2 font-inter  text-[13px] normal-case'>Edit Boat</div>
                    </Button>
                </div>

                <div className='bg-[#E84C4F] rounded-lg flex justify-center items-center my-2 h-8'>
                    <div className='bg-white flex items-center justify-center rounded-full mr-2'><div className='text-gray-700 font-inter h-4 w-4 justify-center items-center flex text-[12px]'>1</div></div><div className='text-gray-700 font-inter font-medium text-sm'>Not able to navigate</div>
                </div>

                {
                    selectedLayout?.withImage &&
                    <img
                        src='./assets/images/boat.jpg'
                        className='rounded-lg w-full aspect-video mb-3 object-cover' />
                }

                <div className='flex flex-col gap-3'>
                    {
                        selectedLayout?.view === 'list' ? listView : gridView
                    }
                </div>
            </div>
        </div>
    )
}
