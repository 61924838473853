import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { userLoginApi } from '../../rest/AuthApi';

export default function LoginForm() {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        setIsLoading(true);
        const result = await userLoginApi(data);
        if (result?.status === 200) {
            enqueueSnackbar('Confirmation email sent', { variant: 'success' });
            navigate('/verification/email/page');
        }
        else if (result?.data?.code === 40411) {
            enqueueSnackbar('Invalid credentials', { variant: 'error' });
        }
        else
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        setIsLoading(false);
    }

    return (
        <form className='mx-4 w-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px]'>Email</div>
            <div>
                <input
                    placeholder='Email address'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("email", {
                        required: "Email is required*",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    })}
                    error={Boolean(errors.email)}
                />
                {
                    errors.email && <div className='text-[11px] text-red-400 mt-1'>{errors.email?.message}</div>
                }
            </div>
            <Button className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] hover:bg-hover-button text-sm rounded-lg w-full mt-6 mb-16 shadow-lg capitalize' type='submit'>{isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : "Login"}</Button>

            <div className="flex items-center justify-center mb-7">
                <hr className="w-1/4 dark:border-white border-custom-bg border-solid border-t mr-2" />
                <p className="text-center dark:text-white text-custom-bg text-[11px] font-inter dark:font-extralight">
                    Don't Have An Account ?
                </p>
                <hr className="w-1/4 dark:border-white border-custom-bg border-solid border-t ml-2" />
            </div>

            <Button className='bg-white hover:bg-gray-100 font-medium font-inter tracking-tight h-10 text-sm text-[#0E9CEC] border-[1px] border-[#0E9CEC] border-solid dark:border-none rounded-lg  w-full capitalize' onClick={() => navigate('/signup')}>Create Account</Button>
        </form>
    )
}
