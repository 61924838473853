import React from 'react';
import Table from '../../Table';
import { services } from '../../../constant/constant';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function HomeWithoutBanner() {
  return (
    <div className='mx-[3vw] sm:mx-[6vw] md:mx-[5vw] flex py-12'>
      <div className='flex flex-col  mr-10 w-1/4'>

        <div className='flex'>
          <div className='font-inter text-xl font-semibold mr-4 dark:text-[#B3B2B2]'>Endeavor <span className='text-[#B3B2B2] dark:text-white'>SP-215</span></div>
        </div>

        <div className='bg-[#DEF1FF] font-inter flex items-center justify-center rounded-lg my-3 h-8 px-6 text-[13px] text-
          .'><span className='h-4 w-4 flex justify-center items-center bg-white rounded-full text-[#21699E] mr-2'><PlusIcon className='w-3 h-3' strokeWidth={2} /></span>Add picture of boat</div>

        <div className='bg-[#F0780A1A] flex items-center justify-center mb-9 rounded-2xl h-8 px-6  text-[13px] text-[#F0780A]'><span className='h-4 w-4 flex justify-center items-center bg-[#F0780A] rounded-full text-[10px] text-white mr-2'>1</span><span className='font-inter'>Repair Pending!</span></div>

        <div className='flex flex-col gap-4 dark:text-white'>
          {
            services.map((item, index) => {
              return (
                <div className='rounded-lg relative shadow-custom h-[6rem] flex items-center px-3 dark:bg-[#253444]'>
                  <img className='h-9 w-9' src={item.imgSrc} />
                  <span className='text-sm font-inter font-medium ml-5'>{item.text}</span>
                  <span style={{ background: item.notificationColor }} className='absolute top-1 right-1 h-4 w-4 flex justify-center items-center rounded-full text-[10px] text-white'>1</span>
                </div>
              )
            })
          }
        </div>

      </div>
      <div className='w-full mt-10'><Table /></div>

    </div>
  )
}
