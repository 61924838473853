import React, { useEffect, useState } from 'react'
import LoginForm from '../forms/LoginForm';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const { t } = useTranslation();
    
    return (
        <div className="dark:bg-custom-bg min-h-screen flex justify-center items-center relative">
            <div className="flex flex-col items-center w-[320px] mx-4">
                <img
                    src='/./assets/icons/logo.svg'
                    className='h-[60px] w-[60px] mb-5'
                />
                <div className='dark:text-white text-custom-bg font-bold font-inter tracking-wide'>
                    {t('boatManagementApp')}
                </div>
                <div className='text-center dark:text-white text-custom-bg font-bold text-xl font-inter tracking-wider my-8 '>
                    {t('login')}
                </div>
                <LoginForm />
            </div>
        </div>
    );
}
