import React from 'react';
import Navbar from '../Navbar';
import AddEngineForm from '../forms/AddEngineForm';

export default function AddEngine() {
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/services/engine.svg'} text={'Add Engine or Generator'} />
            <div className='mx-4 pt-16'>
                <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-4'>Endeavor</div>
                <AddEngineForm />
            </div>
        </div>
    )
}
