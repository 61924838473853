import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { userLoginApi } from '../../rest/AuthApi';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { getCurrentDate } from '../../constant/constant';
import { CustomButton, DateField, InputField, Label, TextArea } from '../Form';


export default function ScheduleNewMaintenanceForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [repairStatusValue, setRepairStatusValue] = useState('Pending');
    const [currentOption, setCurrentOption] = useState('Days');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dateValue, setDateValue] = useState('');
    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const navigate = useNavigate();
    const containerRef = useRef();
    const menuRef = useRef();
    const optionList = ['Days', 'Months', 'Years'];
    const repairStatus = [
        {
            status: 'Pending',
            bgColor: '#F0330A'
        },
        {
            status: 'Completed',
            bgColor: '#23AA0D'
        },
    ]

    // <--------- Close menu when clicked outside --------->
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    // <--------- Handle Date --------->
    function handleDateInput(e) {
        if (e.target.value) {
            clearErrors('dateOfMaintenance');
            const parts = e.target.value.split('-');
            const formattedDate = parts[2] + '/' + parts[1] + '/' + parts[0];
            setDateValue(formattedDate);
        } else {
            setDateValue('');
        }
    }

    // <--------- Menu Options --------->
    const menuOptions = (
        <div
            ref={menuRef}
            className=' rounded-lg absolute bg-white dark:bg-[#bed6e4c4] text-sm left-0 top-12 w-28 z-10 outline-none border dark:border-0 border-gray-300 overflow-hidden shadow-lg'>
            {
                optionList?.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{ border: index === 2 && 0 }}
                            className='flex items-center justify-between bg-white text-sm px-4 border-b-[1px] border-gray-300 h-10' onClick={() => setCurrentOption(item)}>
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )

    function handleRepairStatusInput(e) {
        setRepairStatusValue(e.target.value);
    }

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        console.log(data);
        // setIsLoading(true);
        // const result = await userLoginApi(data);
        // if (result?.status === 203) {
        //     enqueueSnackbar('Confirmation email sent', { variant: 'success' });
        // }
        // else if (result?.status === 400) {
        //     enqueueSnackbar('Invalid credentials', { variant: 'error' });
        // }
        // else
        //     enqueueSnackbar('Something went wrong', { variant: 'error' });
        // setIsLoading(false);
    }

    return (
        <form className='mt-5 w-full' onSubmit={handleSubmit(onSubmit)}>

            <Label text={'Eqipment'} />
            <InputField
                name={'equipment'}
                placeholder={'Eqipment'}
                register={register}
                pattern={{ required: "Field required*" }}
                errors={errors}
            />

            <Label text={'Maintenance to accomplish'} />
            <InputField
                name={'maintenanceToAccomplish'}
                placeholder={'Maintenance to accomplish'}
                register={register}
                pattern={{ required: "Field required*" }}
                errors={errors}
            />

            <Label text={'Date of maintenance'} />
            <DateField
                name={'dateOfMaintenance'}
                register={register}
                pattern={{ required: "Field required*" }}
                errors={errors}
                handleDateInput={handleDateInput}
                dateValue={dateValue}
            />

            <Label text={'Repeat'} />
            <div className='mb-5'>
                <div className='flex gap-3 items-center font-medium'>
                    <span className='dark:text-white mr-3'>Repeat every</span>
                    <input
                        type='number'
                        placeholder='0'
                        className='bg-white rounded-lg text-sm text-center w-12 outline-none border dark:border-0 border-gray-300 h-10'
                        {...register("repeatEvery", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.repeatEvery)}
                    />

                    <div
                        ref={containerRef}
                        className='flex items-center justify-between bg-white rounded-lg text-sm w-28 px-4 outline-none border dark:border-0 border-gray-300 h-10 relative'
                        onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <span>{currentOption}</span>
                        <ChevronDownIcon className='h-4 w-4' strokeWidth={2} />
                        {isMenuOpen && menuOptions}
                    </div>
                </div>
                {
                    errors.repeatEvery && <div className='text-[11px] text-red-400 mt-1'>{errors.repeatEvery?.message}</div>
                }
            </div>

            <Label text={'Status'} />
            <div className='flex justify-between w-full gap-3 mb-5'>
                {
                    repairStatus.map((item, index) => {
                        return (
                            <label
                                key={index}
                                style={{
                                    background: repairStatusValue === item?.status && item?.bgColor,
                                    color: repairStatusValue === item?.status && 'white',
                                    border: repairStatusValue === item?.status && 0
                                }}
                                className='flex justify-center items-center rounded-lg font-semibold text-[12px] bg-white w-full h-8 outline-none border dark:border-0 border-gray-300'>
                                <input
                                    type='radio'
                                    value={item.status}
                                    className='absolute opacity-0'
                                    {...register("repairStatus", { required: "Field required*" })}
                                    error={Boolean(errors.repairStatus)}
                                    onChange={handleRepairStatusInput}
                                    checked={repairStatusValue === item?.status && true}
                                />
                                {item.status}
                            </label>
                        )
                    })
                }
            </div>

            <Label text={'Notes'} />
            <TextArea
                rows={5}
                name={'notes'}
                placeholder={'Notes'}
                register={register}
                pattern={{ required: "Field required*" }}
                errors={errors}
            />

            <CustomButton text={'Add Schedule Maintenance'} isLoading={isLoading} />

        </form>
    )
}
