import React from 'react';
import { useRecoilState } from 'recoil';
import { appTheme } from '../recoil/store';
import { SunIcon } from '@heroicons/react/24/outline';
import { MoonIcon } from '@heroicons/react/24/solid';

export default function ThemeIcon() {
    const [theme, setTheme] = useRecoilState(appTheme);
    return (
        <div className='absolute top-6 sm:top-10 right-6 sm:right-16 z-10 cursor-pointer h-6 w-6' onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
            {theme === "dark" ? <SunIcon className='text-white'/> : <MoonIcon/>}
        </div>
    )
}
