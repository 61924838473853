import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export default function ResendEmailVerificationForm() {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        // setIsLoading(true);
        // const result = await resendEmailVerificationApi(data);
        // if (result?.status === 200) {
        //     navigate('/verification/email');
        // }
        // else if (result?.status === 400) {
        //     enqueueSnackbar('Email already verified', { variant: 'error' })
        // }
        // else if (result?.status === 500) {
        //     enqueueSnackbar('Email not exists', { variant: 'error' })
        // }
        // else
        //     enqueueSnackbar('Something went wrong', { variant: 'error' })
        // setIsLoading(false);
    }

    return (
        <form className='mx-4 w-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px]'>Email</div>
            <div>
                <input
                    placeholder='Email address'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("email", {
                        required: "Email is required*",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    })}
                    error={Boolean(errors.email)}
                />
                {
                    errors.email && <div className='text-[11px] text-red-400 mt-1'>{errors.email?.message}</div>
                }
            </div>
            <Button className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] text-sm rounded-lg w-full mt-6 mb-16 shadow-lg hover:bg-hover-button capitalize' type='submit'>
                {isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : "Resend Verification Link"}
            </Button>
        </form>
    )
}
