import React from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from '../recoil/store';
import ThemeIcon from '../components/ThemeIcon';

export default function UnprotectedRoute() {
    const isAuth = useRecoilValue(isAuthenticated);
    return (
        isAuth ? <Navigate to='/'/> : <div><ThemeIcon/><Outlet/></div>
    )
}