import axios from "axios";
import store from "store";
import { ACCESS_TOKEN, getDeviceId } from "../constant/constant";

// <--------- Auth Instance --------->
export const authInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/user/auth`
});

authInstance.interceptors.request.use(async (request) => {
    request.headers['X-DEVICE-ID'] = getDeviceId();
    return request;
});

// <--------- User Instance --------->
export const userInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/auth/user`
});

userInstance.interceptors.request.use(async (request) => {
    const accessToken = store.get(ACCESS_TOKEN);
    request.headers['Authorization'] = 'Bearer ' + accessToken;
    return request;
});