import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { userLoginApi } from '../../rest/AuthApi';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CustomButton, InputField, Label } from '../Form';

export default function AddEngineForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [repairStatusValue, setRepairStatusValue] = useState('Pending');
    const [boatStatusValue, setBoatStatusValue] = useState('Repair Pending');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();


    // <--------- Form submit function --------->
    async function onSubmit(data) {
        console.log(data);
        // setIsLoading(true);
        // const result = await userLoginApi(data);
        // if (result?.status === 203) {
        //     enqueueSnackbar('Confirmation email sent', { variant: 'success' });
        // }
        // else if (result?.status === 400) {
        //     enqueueSnackbar('Invalid credentials', { variant: 'error' });
        // }
        // else
        //     enqueueSnackbar('Something went wrong', { variant: 'error' });
        // setIsLoading(false);
    }
    return (
        <form className='mt-5 w-full' onSubmit={handleSubmit(onSubmit)}>

            <Label text={'Engine designation'} />
            <InputField
                name={'engineDesignation'}
                placeholder={'PORT Engine'}
                register={register}
                pattern={{ required: "Field required*" }}
                errors={errors}
            />

            <div className='grid grid-cols-3 mx-5 gap-4 mt-5'>
                <div>
                    <Label text={'Hours'} />
                    <input
                        placeholder='3'
                        type='number'
                        className='bg-white rounded-lg text-sm text-center px-2  outline-none border dark:border-0 border-gray-300 w-full h-10'
                        {...register("hours", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.hours)}
                    />
                </div>

                <div>
                    <Label text={'Oil'} />
                    <input
                        placeholder='3'
                        type='number'
                        className='bg-white rounded-lg text-sm text-center px-2 outline-none border dark:border-0 border-gray-300 w-full h-10'
                        {...register("oil", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.oil)}
                    />
                </div>

                <div>
                    <Label text={'Fuel'} />
                    <input
                        placeholder='3'
                        type='number'
                        className='bg-white rounded-lg text-sm text-center px-2  outline-none border dark:border-0 border-gray-300 w-full h-10'
                        {...register("fuel", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.fuel)}
                    />
                </div>

            </div>

            {
                (errors.hours || errors.oil || errors.fuel) && <div className='text-[11px] text-red-400 mt-1'>All fields required*</div>
            }

            <CustomButton text={'Add engine'} isLoading={isLoading} />

        </form>
    )
}
