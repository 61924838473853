import { atom } from "recoil";
import Cookies from 'js-cookie';
import { APP_LAYOUT, APP_THEME, HOME_POPUP } from "../constant/constant";

function theme() {
    const theme = Cookies.get(APP_THEME);
    if (theme) return theme
    else return "dark"
}

function layout() {
    let layout = Cookies.get(APP_LAYOUT);
    if (layout) {
        layout = JSON.parse(layout);
        return layout;
    }
    else return { view: 'list', withImage: true }
}

export const appTheme = atom({
    key: "appTheme",
    default: theme()
});

export const isAuthenticated = atom({
    key: "isAuthenticated",
    default: false
});

export const user = atom({
    key: "userDetails",
    default: {}
});

export const appLayout = atom({
    key: "layout",
    default: layout()
});