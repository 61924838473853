
import React, { useEffect, useState } from 'react';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import { Route, Routes } from 'react-router-dom';
import VerificationRoute from './routes/Verification/VerificationRoute';
import ResendEmailVerification from './components/pages/ResendEmailVerification';
import ProtectedRoute from './routes/ProtectedRoute';
import UnprotectedRoute from './routes/UnProtectedRoute';
import Home from './components/pages/home/Home';
import Loader from './components/Loader';
import { getuserDetailsApi } from './rest/UserApi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appLayout, appTheme, isAuthenticated, user } from './recoil/store';
import store from 'store';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, APP_LAYOUT, APP_THEME, SESSION_ID } from './constant/constant';
import Repair from './components/pages/Repair';
import AddNewRepair from './components/pages/AddNewRepair';
import ScheduleMaintenance from './components/pages/ScheduleMaintenance';
import ScheduleNewMaintenance from './components/pages/ScheduleNewMaintenance';
import Logbook from './components/pages/Logbook';
import AddNewLogbookEntry from './components/pages/AddNewLogbookEntry';
import Engine from './components/pages/Engine';
import AddEngine from './components/pages/AddEngine';
import Customise from './components/pages/Customise';
import ChangeLayout from './components/pages/ChangeLayout';
import ChangeLanguage from './components/pages/ChangeLanguage';
import InviteOthers from './components/pages/InviteOthers';
import AppVersion from './components/pages/AppVersion';
import HomeMobile from './components/pages/home/HomeMobile';
import BmaApp from './components/BmaApp';
import { checkLoginApi } from './rest/AuthApi';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const setIsLoggedIn = useSetRecoilState(isAuthenticated);
  const setUserDetails = useSetRecoilState(user);
  const selectedAppTheme = useRecoilValue(appTheme);
  const selectedAppLayout = useRecoilValue(appLayout);

  // <--------- Checking loggedIn User  --------->
  async function checkForLoggedInUser() {
    const result = await checkLoginApi();
    if (result?.status === 200) {
      store.set(SESSION_ID, result?.data?.sessionId);
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    checkForLoggedInUser();
  }, [])

  // <--------- Theme change --------->
  useEffect(() => {
    Cookies.set(APP_THEME, selectedAppTheme);
    if (selectedAppTheme === 'dark')
      document.querySelector('html').classList.add('dark');
    else
      document.querySelector('html').classList.remove('dark');
  }, [selectedAppTheme]);

  // <--------- Layout change --------->
  useEffect(() => {
    Cookies.set(APP_LAYOUT, JSON.stringify(selectedAppLayout));
  }, [selectedAppLayout]);

  if (isLoading) {
    return (
      <div className='h-screen'><Loader /></div>
    )
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/repair' element={<Repair />} />
        <Route path='/addRepair' element={<AddNewRepair />} />
        <Route path='/logbook' element={<Logbook />} />
        <Route path='/addEntry' element={<AddNewLogbookEntry />} />
        <Route path='/maintenance' element={<ScheduleMaintenance />} />
        <Route path='/addMaintenance' element={<ScheduleNewMaintenance />} />
        <Route path='/engine' element={<Engine />} />
        <Route path='/addEngine' element={<AddEngine />} />
        <Route path='/customise' element={<Customise />} />
        <Route path='/layout' element={<ChangeLayout />} />
        <Route path='/language' element={<ChangeLanguage />} />
        <Route path='/invite' element={<InviteOthers />} />
        <Route path='/version' element={<AppVersion />} />
      </Route>
      <Route element={<UnprotectedRoute />}>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
      </Route>
      <Route path='/verification/*' element={<VerificationRoute />} />
      <Route path='/app/:id?' element={<BmaApp />} />
      <Route path='/test' element={<ResendEmailVerification />} />
    </Routes>
    // <Routes>
    //   <Route>
    //     <Route path='/' element={<Home />} />
    //     <Route path='/customise' element={<Customise />} />
    //     <Route path='/layout' element={<ChangeLayout />} />
    //   </Route>
    // </Routes>
    // <DownloadOrOpenApp />
    // <Login />
  )
}

export default App;
