import React from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from '../recoil/store';

export default function ProtectedRoute() {
    const isAuth = useRecoilValue(isAuthenticated);
    return (
        isAuth ? <Outlet/> : <Navigate to='/login'/>
    )
}