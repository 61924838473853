import React from 'react'
import Navbar from '../Navbar'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useRecoilState, useRecoilValue } from 'recoil';
import { appTheme, appLayout } from '../../recoil/store';
import { appLayoutList } from '../../constant/constant';

export default function ChangeLayout() {
    const [selectedAppLayout, setSelectedAppLayout] = useRecoilState(appLayout);
    const selectedAppTheme = useRecoilValue(appTheme);

    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/logo.svg'} text={'Layout'} />
            <div className='mx-4 pt-16'>
                <span className='font-inter text-lg dark:text-white font-semibold'>Change Layout</span>
                <div className='grid grid-cols-2 gap-5 mt-4 w-full'>
                    {
                        appLayoutList?.map((item, index) => {
                            return (
                                <div onClick={() => setSelectedAppLayout(item.layout)}>
                                    <div
                                        key={index}
                                        style={{ border: JSON.stringify(selectedAppLayout) === JSON.stringify(item.layout) && '2px solid #0E9CEC' }}
                                        className='flex justify-center p-3 shadow-custom rounded-lg dark:bg-[#364D66] bg-[#BBE7FF] relative'>
                                        <img
                                            src={selectedAppTheme === 'dark' ? item.imgSrcDark : item.imgSrcLight}
                                            className='h-32 object-contain rounded'
                                            loading='lazy' />
                                        <div
                                            style={{ display: JSON.stringify(selectedAppLayout) !== JSON.stringify(item.layout) && 'none' }}
                                            className='absolute -top-2 -right-2 rounded-full p-1 bg-custom-blue'>
                                            <CheckIcon className='w-4 text-white' strokeWidth={2} />
                                        </div>
                                    </div>
                                    <div className='text-[10px] text-center mt-1 dark:text-white'>{item.text}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
