import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EmailVerification from '../../components/pages/EmailVerification';
import Verification from './Verification';

export default function VerificationRoute() {
    return (
        <Routes>
            <Route path='/email' element={<Verification />} />
            <Route path='/email/page' element={<EmailVerification />} />
        </Routes>
    )
}
