import React from 'react';
import AddNewRepairForm from '../forms/AddNewRepairForm';
import Navbar from '../Navbar';

export default function AddNewRepair() {
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/services/repair.svg'} text={'Add New Repair'} />
            <div className='mx-4 pt-16'>
                <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-4'>Endeavor</div>
                <div
                    className='bg-[#FF762D] rounded-lg flex justify-center items-center h-8 text-white font-inter font-medium text-[13px]'>
                    Repair Pending
                </div>
                <AddNewRepairForm />
            </div>
        </div>
    )
}
