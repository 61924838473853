import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { userLoginApi } from '../../rest/AuthApi';
import { PlusIcon } from '@heroicons/react/24/outline';
import { postNewRepairApi } from '../../rest/ServiceApi';

export default function RepairForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [repairStatusValue, setRepairStatusValue] = useState('Pending');
    const [boatStatusValue, setBoatStatusValue] = useState('Repair Pending');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const repairStatus = [
        {
            status: 'Pending',
            bgColor: '#F0330A'
        },
        {
            status: 'In Process',
            bgColor: '#D7AA0B'
        },
        {
            status: 'Completed',
            bgColor: '#23AA0D'
        },
    ]

    const boatStatus = [
        {
            status: 'Repair Pending',
            bgColor: '#FF762D'
        },
        {
            status: 'In Process',
            bgColor: '#D7AA0B'
        },
        {
            status: 'Completed',
            bgColor: '#23AA0D'
        },
    ]

    function handleRepairStatusInput(e) {
        setRepairStatusValue(e.target.value);
    }

    function handleBoatStatusInput(e) {
        setBoatStatusValue(e.target.value);
    }


    // <--------- Form submit function --------->
    async function onSubmit(data) {
        console.log(data);

        const formData = new FormData();
        formData.append('abc', 'sda')
        setIsLoading(true);
        const result = await postNewRepairApi(formData);
        if (result?.status === 201) {
            enqueueSnackbar('Confirmation email sent', { variant: 'success' });
        }
        else if (result?.status === 400) {
            enqueueSnackbar('Invalid credentials', { variant: 'error' });
        }
        else
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        setIsLoading(false);
    }
    return (
        <form className='mt-5 w-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px]'>Equipment</div>
            <div>
                <input
                    placeholder='Equipment'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("equipment", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.equipment)}
                />
                {
                    errors.equipment && <div className='text-[11px] text-red-400 mt-1'>{errors.equipment?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Fault</div>
            <div>
                <input
                    placeholder='Fault'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("fault", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.fault)}
                />
                {
                    errors.fault && <div className='text-[11px] text-red-400 mt-1'>{errors.fault?.message}</div>
                }
            </div>


            <div className='dark:text-white text-custom-bg  font-inter mb-2 mt-5 text-[13px]'>Repair Status</div>
            <div className='flex justify-between w-full gap-3'>
                {
                    repairStatus.map((item, index) => {
                        return (
                            <label
                                key={index}
                                style={{
                                    background: repairStatusValue === item?.status && item?.bgColor,
                                    color: repairStatusValue === item?.status && 'white',
                                    border: repairStatusValue === item?.status && 0
                                }}
                                className='flex justify-center items-center rounded-lg font-semibold text-[12px] bg-white w-full h-8 outline-none border dark:border-0 border-gray-300'>
                                <input
                                    type='radio'
                                    value={item.status}
                                    className='absolute opacity-0'
                                    {...register("repairStatus", { required: "Field required*" })}
                                    error={Boolean(errors.repairStatus)}
                                    onChange={handleRepairStatusInput}
                                    checked={repairStatusValue === item?.status && true}
                                />
                                {item.status}
                            </label>
                        )
                    })
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-2 mt-5 text-[13px]'>Boat Status</div>
            <div className='flex flex-col w-full gap-3'>
                {
                    boatStatus.map((item, index) => {
                        return (
                            <label
                                key={index}
                                style={{
                                    background: boatStatusValue === item?.status && item?.bgColor
                                }}
                                className='flex justify-center items-center rounded-lg font-semibold text-[12px] text-white bg-[#767676] w-full h-8'>
                                <input
                                    type='radio'
                                    className='absolute opacity-0'
                                    value={item.status}
                                    {...register("boatStatus", { required: "Field required*" })}
                                    error={Boolean(errors.boatStatus)}
                                    onChange={handleBoatStatusInput}
                                    checked={boatStatusValue === item?.status && true}
                                />
                                {item.status}
                            </label>
                        )
                    })
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Upload Media</div>
            <div>
                <label>
                    <input
                        type='file'
                        hidden
                        {...register("media", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.media)}
                    />
                    <div className='flex justify-center items-center bg-white rounded-lg text-sm w-full outline-none border dark:border-0 border-gray-300 h-10 font-medium'>
                        <PlusIcon className='h-5 w-5 mr-1' strokeWidth={2} />
                        <span>Upload media</span>
                    </div>
                </label>
                {
                    errors.media && <div className='text-[11px] text-red-400 mt-1'>{errors.media?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Notes</div>
            <div>
                <textarea
                    rows={5}
                    placeholder='Notes'
                    className='flex justify-center items-center bg-white rounded-lg px-4 py-3 text-sm w-full outline-none border dark:border-0 border-gray-300'
                    {...register("notes", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.notes)}
                />
                {
                    errors.notes && <div className='text-[11px] text-red-400 mt-1'>{errors.notes?.message}</div>
                }
            </div>

            <Button className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] hover:bg-hover-button text-sm rounded-lg w-full mt-6 mb-16 shadow-lg capitalize' type='submit'>{isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : "Add repair"}</Button>
        </form>
    )
}
