import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { isAuthenticated } from '../../recoil/store';
import { Navigate, useNavigate } from 'react-router-dom';
import store from 'store';
import { SESSION_ID, downloadOrOpenApp, isMobile } from '../../constant/constant';
import { checkLoginApi } from '../../rest/AuthApi';

export default function EmailVerification() {
    const [seconds, setSeconds] = useState(59 * 60 + 59);
    const setIsLoggedIn = useSetRecoilState(isAuthenticated);
    const navigate = useNavigate();

    // <--------- Login Check --------->
    async function loginCheck() {
        const result = await checkLoginApi();
        if (result?.status === 200) {
            store.set(SESSION_ID, result?.data?.sessionId);
            setIsLoggedIn(true);
            navigate('/');
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            loginCheck();
        }, 5000);
        return () => clearInterval(intervalId);
    }, [])

    // <--------- Timer --------->
    useEffect(() => {
        let interval = null;
        if (seconds === 0)
            clearInterval(interval)
        else {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [seconds]);

    const loginSuccessJsx = (
        <div className="dark:bg-custom-bg min-h-screen flex justify-center items-center relative">
            <div className="flex flex-col items-center w-[300px] sm:w-[350px] mx-4">
                <img
                    src='/./assets/icons/logo.svg'
                    className='h-[60px] w-[60px] mb-5'
                />
                <div className='dark:text-white text-custom-bg font-bold font-inter tracking-wide'>BMA</div>

                <div
                    className='text-center dark:text-white text-custom-bg font-bold font-inter text-xl tracking-wider my-8'>
                    Login Successful
                </div>

                <div className='mx-4 w-full'>
                    <div className='dark:text-white text-custom-bg text-center text-sm font-light leading-[24px]'>
                        Welcome to BMA. To continue, please close this browser window and open the BMA APP directly from your mobile and enjoy all the features on the go (recommended).
                    </div>
                    <Button
                        className='text-white font-medium  tracking-wide h-10 bg-[#0E9CEC] text-sm rounded-lg mt-6 shadow-lg capitalize w-full border border-red-700'
                        onClick={downloadOrOpenApp}>
                        Open in App
                    </Button>
                    <div
                        className='text-gray-400 dark:text-gray-300 text-center font-extralight text-xs leading-[24px] mt-7 mb-5'>
                        If you wish to use our app via the web, click the button below to use our web version.
                    </div>

                    <div
                        className='text-[#0E9CEC] text-center font-light underline cursor-pointer'
                        onClick={() => navigate('/')}>
                        BMA Web Version
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="dark:bg-custom-bg min-h-screen flex justify-center items-center relative">
            <div className="flex flex-col items-center w-[300px] sm:w-[350px] mx-4">
                <img
                    src='/./assets/icons/logo.svg'
                    className='h-[60px] w-[60px] mb-5'
                />
                <div className='dark:text-white text-custom-bg font-bold font-inter tracking-wide'>BMA</div>

                <div className='text-center dark:text-white text-custom-bg text-xl font-bold font-inter tracking-wider my-8'>Email Verification</div>
                <div className='mx-4 w-full'>

                    <div className='dark:text-white text-custom-bg text-center font-inter font-light text-sm leading-[24px]'>We sent an email from <span className='text-[#0E9CEC]'>bmasupport@ifalcon.net</span> to the email address you used to register.  Please check for a confirmation email requesting to verify your email address. This verification has a <span className='text-[#0E9CEC]'>1 hour</span> time limit.</div>
                    {
                        seconds === 0 ? <div className='text-red-500 text-center font-inter font-light text-sm mt-8'>Link Expired</div> :
                            <>
                                <div className='dark:text-white text-custom-bg text-center font-inter font-extralight text-[10px] mt-8 mb-4'>Countdown Timer</div>
                                <div className='text-[#40EE6F] text-center font-inter font-light text-sm'>{Math.floor(seconds / 60)} minutes {seconds % 60} seconds</div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
