import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import { useRecoilValue } from 'recoil';
import { appLayout } from '../../../recoil/store';
import HomeWithBanner from './HomeWithBanner';
import HomeWithoutBanner from './HomeWithoutBanner';
import { useMediaQuery } from 'react-responsive';
import HomeMobile from './HomeMobile';
import Popup from '../../Popup';
import Cookies from 'js-cookie';
import { HOME_POPUP } from '../../../constant/constant';

export default function Home() {
  const selectedLayout = useRecoilValue(appLayout);
  const isMobileScreen = useMediaQuery({ maxWidth: 640 });
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  // <--------- Getting popup value from cookie --------->
  // useEffect(() => {
  //   const popup = Cookies.get(HOME_POPUP);
  //   popup === 'false' ? setIsPopupOpen(false) : setIsPopupOpen(true);
  // }, [])

  return (
    <div className='dark:bg-custom-bg min-h-screen'>
      {isPopupOpen && <Popup setIsPopupOpen={setIsPopupOpen} />}
      {<Navbar home={true} text={'BMA'} />}
      {isMobileScreen ? <HomeMobile /> : (selectedLayout === 'list' ? <HomeWithoutBanner /> : <HomeWithBanner />)}
    </div>
  )
}
