import React from 'react'
import Navbar from '../Navbar'
import { MoonIcon, PhotoIcon, SunIcon } from '@heroicons/react/24/outline'
import { appLayout, appTheme } from '../../recoil/store';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { appLayoutList } from '../../constant/constant';

export default function Customise() {
    const [selectedAppTheme, setSelectedAppTheme] = useRecoilState(appTheme);
    const selectedAppLayout = useRecoilValue(appLayout);
    const navigate = useNavigate();
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/logo.svg'} text={'Customise'} />
            <div className='mx-4 pt-16'>
                <span className='font-inter text-lg dark:text-white font-semibold'>Appearance</span>
                <div className='flex justify-center mt-4 p-4 shadow-custom rounded-lg dark:bg-[#364D66] bg-[#BBE7FF]'>
                    {
                        appLayoutList.map((item, index) => {
                            if (JSON.stringify(selectedAppLayout) === JSON.stringify(item.layout)) {
                                return (
                                    <img
                                        key={index}
                                        src={selectedAppTheme === 'dark' ? item.imgSrcDark : item.imgSrcLight}
                                        className='h-60 object-contain rounded'
                                        loading='lazy'
                                    />
                                )
                            }
                        })
                    }
                </div>

                <div className='flex justify-center items-center mt-10 mb-6 p-3 shadow-custom rounded-lg dark:bg-[#364D66] bg-[#BBE7FF]' onClick={() => navigate('/layout')}>
                    <PhotoIcon className='h-5 dark:text-white' />
                    <span className='font-inter text-sm ml-2 dark:text-white'>Change Layout</span>
                </div>

                <div className='border-b-[1px] dark:border-white border-gray-300'></div>

                <div className='font-inter text-sm ml-2 dark:text-white my-4'>Mode</div>

                <div className='grid grid-cols-2 dark:border-0 border-[1px] border-[#BBE7FF] rounded-3xl bg-white h-9 p-[2px]'>
                    <div
                        className='flex justify-center items-center dark:bg-[#364D66] rounded-2xl'
                        onClick={() => setSelectedAppTheme('dark')}>
                        <MoonIcon className='h-4 w-4 dark:text-white text-black' />
                        <span className='font-inter text-sm ml-2 dark:text-white text-black'>Dark</span>
                    </div>
                    <div
                        className='flex justify-center items-center dark:bg-white bg-[#BBE7FF] rounded-2xl'
                        onClick={() => setSelectedAppTheme('light')}>
                        <SunIcon className='h-4 w-4' />
                        <span className='font-inter text-sm ml-2'>Light</span>
                    </div>
                </div>

            </div>
        </div>
    )
}
