import React from 'react'
import { socialMediaIconList } from '../../constant/constant'
import Navbar from '../Navbar'

export default function InviteOthers() {
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/logo.svg'} text={'Invite Others'} />
            <div className='mx-4 pt-16 flex flex-col items-center justify-center'>
                <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-4'>Endeavor</div>
                <div className='dark:bg-[#364D66] bg-[#BBE7FF] p-4 rounded-lg'>
                    <img src='./assets/images/invite.svg' className='w-52' loading='lazy'/>
                </div>
                <p className='font-inter dark:text-white text-sm dark:font-light text-center mt-5'>Invite up to 5 others to join your account. For example, send invitation to owner, captain, mates, secretary or boat  administrator. Select from the following options to send invitation:</p>
                <div className='w-full'>
                    <div className='dark:text-white text-custom-bg  font-inter mb-1 text-[13px] mt-5 '>Email</div>
                    <input
                        placeholder='Email address'
                        className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300  h-10'
                    // {...register("email", {
                    //     required: "Field required*",
                    //     pattern: {
                    //         value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    //         message: "Invalid email*"
                    //     }
                    // })}
                    // error={Boolean(errors.email)}
                    />
                    {/* {
                    errors.email && <div className='text-[11px] text-red-400 mt-1'>{errors.email?.message}</div>
                } */}
                    <h2 className='font-inter text-center dark:text-white mt-7 mb-9 font-medium'>Or Invite via</h2>
                    <div className='flex gap-6 justify-center'>
                        {
                            socialMediaIconList.map((item, index) => {
                                return (
                                        <img key={index} src={item.imgSrc} className='h-10' loading='lazy'/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
