import React from 'react'
import Navbar from '../Navbar'

export default function AppVersion() {
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/logo.svg'} text={'Version'} />
            <div className='mx-4 pt-16'>
                <span className='font-inter text-lg dark:text-white font-semibold'>Current Version</span>
                <div className='font-inter text-gray-400 font-medium'>v0.5.4</div>
            </div>
        </div>
    )
}
