import { CircularProgress } from '@mui/material'
import React from 'react'

export default function () {
  return (
    <div className=' dark:bg-custom-bg flex justify-center items-center h-full w-full'>
      <CircularProgress sx={{ color: '#0E9CEC' }} />
    </div>
  )
}
