import React from 'react'
import { Delivered } from './Helper'
import { useRecoilValue } from 'recoil'
import { appTheme } from '../recoil/store'

export default function Table() {
    const theme = useRecoilValue(appTheme);
    const arr = [1, 2, 3, 4, 5];
    return (
        <table className="table-auto w-full shadow-custom rounded-lg dark:text-white overflow-hidden">
            <thead className='text-sm font-inter h-12 dark:bg-[#364D66]'>
                <tr>
                    <th>Repair ID</th>
                    <th><div className='flex items-center'><span>Equipment</span><img className='h-[1rem] w-[1rem] ml-2 cursor-pointer' src='./assets/icons/sort.svg' /></div></th>
                    <th><div className='flex items-center'><span>Task Name</span><img className='h-[1rem] w-[1rem] ml-2 cursor-pointer' src='./assets/icons/sort.svg' /></div></th>
                    <th><div className='flex items-center'><span>Date</span><img className='h-[1rem] w-[1rem] ml-2 cursor-pointer' src='./assets/icons/sort.svg' /></div></th>
                    <th className='text-start'>Amount</th>
                    <th className='text-start'>Assigned Top</th>
                    <th><div className='flex items-center'><span>Status</span><img className='h-[1rem] w-[1rem] ml-2 cursor-pointer' src='./assets/icons/sort.svg' /></div></th>
                    <th className='text-start'>Action</th>
                </tr>
            </thead>
            <tbody className='font-light'>
                {
                    arr.map((item, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: theme === 'dark' ? (index % 2 === 0 ? '#253444' : '#364D66') : (index % 2 === 0 && '#BED6E442') }} className=' text-sm font-inter h-16'>
                                <td className='text-center'>#20462</td>
                                <td className=''><div className='flex items-center justify-start'><img className='h-8 w-8 mr-4 object-cover rounded-lg' src='./assets/images/hat.jpg' /><span>Hat</span></div></td>
                                <td>Matt Dickerson</td>
                                <td>13/05/2022</td>
                                <td>$4.95</td>
                                <td>Transfer Bank</td>
                                <td><Delivered /></td>
                                <td><span className='flex items-center'><img className='h-5 w-5 cursor-pointer' src='./assets/icons/edit.svg' /> <img className='h-5 w-5 ml-2 cursor-pointer' src='./assets/icons/trash.svg' /></span></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
