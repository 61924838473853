import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';

export default function Logbook() {
    const navigate = useNavigate();
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/services/logbook.svg'} text={'Logbook'} />
            <div className='mx-4 pt-16'>
                <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-7'>Endeavor</div>
                <div className='flex justify-end'>
                    <Button className='flex items-center bg-custom-blue rounded-md px-3 py-1' onClick={() => navigate('/addEntry')}>
                        <PlusIcon className='h-4 w-4 text-white' strokeWidth={2} />
                        <div className='text-white ml-1 font-inter text-[13px] normal-case'>Add Entry</div>
                    </Button>
                </div>
                {
                    [1, 2, 3, 4].map((item, index) => {
                        return (
                            <div className='flex rounded-lg dark:text-white dark:bg-[#253444] bg-[#EEEEEE] overflow-hidden pr-4 mt-3'>
                                <div className='w-[0.4rem] mr-3 bg-[#E253F5]'></div>
                                <div className='flex flex-col justify-between w-full py-4'>
                                    <div className='w-full'>

                                        <div className='flex justify-between text-[14px] mb-2'>
                                            <span className='font-medium text-[#E253F5]'>Log ID: <span className='text-black dark:text-white'>25</span></span>
                                            <span className='font-medium text-[#FC8C08]'>04ABR24</span>
                                        </div>

                                        <div className='flex justify-between text-[13px] dark:text-white'>
                                            <span className='font-medium'>From: <span className='font-normal dark:font-light'>Panama</span></span>
                                            <span className='font-medium'>Crew: <span className='font-normal dark:font-light'>3</span></span>
                                        </div>

                                        <div className='flex justify-between text-[13px] dark:text-white'>
                                            <span className='font-medium'>To: <span className='font-normal dark:font-light'>Contadora</span></span>
                                            <span className='font-medium'>Passengers: <span className='font-normal dark:font-light'>7</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
