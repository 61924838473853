import axios from "axios";

const BASE_URL = 'http://77.37.63.64:8101/bma'

// <--------- Get All Repairs --------->
export const getAllRepairsApi = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/repair/boat/1`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Post New Repairs --------->
export const postNewRepairApi = async (body) => {
    try {
        const result = await axios.post(`${BASE_URL}/repair/boat/1`, body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        console.log(error)
        return error?.response;
    }
}