import React, { useState } from 'react';
import { languageList } from '../../constant/constant';
import Navbar from '../Navbar';
import { CheckIcon } from '@heroicons/react/24/outline';

export default function ChangeLanguage() {
    const [currentLanguage, setCurrentLanguage] = useState(0);
    return (
        <div className='min-h-screen dark:bg-custom-bg pb-4'>
            <Navbar imgIconSrc={'/./assets/icons/logo.svg'} text={'Language'} />
            <div className='mx-4 pt-16'>
                <span className='font-inter text-lg dark:text-white font-semibold'>Choose Language</span>
                <div className='flex flex-col gap-4 mt-4'>
                    {
                        languageList.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{ border: index === currentLanguage && '2px solid #0E9CEC' }}
                                    className='flex items-center py-2 px-4 border-[1px] dark:bg-[#364D66] dark:border-0 border-gray-300 rounded-lg relative'
                                    onClick={() => setCurrentLanguage(index)}>
                                    <img
                                        src={item.imgSrc}
                                        className='mr-3 h-6 w-6 object-cover rounded-full shadow-lg'
                                        loading='lazy'
                                    />
                                    <span className='flex-1 text-gray-700 font-inter dark:text-white'>{item.text}</span>
                                    <div
                                        style={{ display: index !== currentLanguage && 'none' }}
                                        className='absolute -top-2 -right-2 rounded-full p-1 bg-custom-blue'>
                                        <CheckIcon className='w-3 text-white' strokeWidth={2} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
