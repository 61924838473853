import store from 'store';
import { v4 as uuidv4 } from 'uuid';
export const SESSION_ID = 'BMA_SESSION_ID';
export const DEVICE_ID = 'BMA_DEVICE_ID';
export const ACCESS_TOKEN = 'BMA_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'BMA_REFRESH_TOKEN';
export const APP_THEME = 'BMA_APP_THEME';
export const APP_LAYOUT = 'BMA_APP_LAYOUT';
export const HOME_POPUP = 'HOME_POPUP';
export const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=com.yourapp';

// <--------- App Layout List --------->
export const appLayoutList = [
    {
        text: 'BMA Default',
        layout: { view: 'list', withImage: true },
        imgSrcLight: './assets/images/layout/Light_List_Image.png',
        imgSrcDark: './assets/images/layout/Dark_List_Image.png'
    },
    {
        text: 'Grid View (With Image)',
        layout: { view: 'grid', withImage: true },
        imgSrcLight: './assets/images/layout/Light_Grid_Image.png',
        imgSrcDark: './assets/images/layout/Dark_Grid_Image.png'
    },
    {
        text: 'List View (Without Image)',
        layout: { view: 'list', withImage: false },
        imgSrcLight: './assets/images/layout/Light_List_NoImage.png',
        imgSrcDark: './assets/images/layout/Dark_List_NoImage.png'
    },
    {
        text: 'Grid View (Without Image)',
        layout: { view: 'grid', withImage: false },
        imgSrcLight: './assets/images/layout/Light_Grid_NoImage.png',
        imgSrcDark: './assets/images/layout/Dark_Grid_NoImage.png'
    }
]

// <--------- Social Media --------->
export const socialMediaIconList = [
    {
        imgSrc: './assets/icons/socialMedia/whatsapp.svg',
        url: ''
    },
    {
        imgSrc: './assets/icons/socialMedia/instagram.svg',
        url: ''
    },
    {
        imgSrc: './assets/icons/socialMedia/facebook.svg',
        url: ''
    },
    {
        imgSrc: './assets/icons/socialMedia/linkedin.svg',
        url: ''
    }
]

// <--------- List of languages --------->
export const languageList = [
    {
        imgSrc: '/./assets/icons/flags/uk.svg',
        text: 'English'
    },
    {
        imgSrc: '/./assets/icons/flags/china.svg',
        text: '中国人'
    },
    {
        imgSrc: '/./assets/icons/flags/spain.svg',
        text: 'española'
    },
    {
        imgSrc: '/./assets/icons/flags/india.svg',
        text: 'हिंदी'
    },
    {
        imgSrc: '/./assets/icons/flags/france.svg',
        text: 'française'
    },
    {
        imgSrc: '/./assets/icons/flags/portugal.svg',
        text: 'português'
    },
    {
        imgSrc: '/./assets/icons/flags/russia.svg',
        text: 'русский'
    }
]

// <--------- List of services --------->
export const services = [
    {
        imgSrc: './assets/icons/services/repair.svg',
        text: 'Repairs',
        notificationColor: '#E84C4F',
        url: '/repair'
    },
    {
        imgSrc: './assets/icons/services/time.svg',
        text: 'Maintenance',
        notificationColor: '#40EE6F',
        url: '/maintenance'
    },
    {
        imgSrc: './assets/icons/services/engine.svg',
        text: 'Engines',
        notificationColor: '#FF762D',
        url: '/engine'
    },
    {
        imgSrc: './assets/icons/services/logbook.svg',
        text: 'Logbook',
        notificationColor: '#E253F5',
        url: '/logbook'
    },
    {
        imgSrc: './assets/icons/services/trip.svg',
        text: 'Scheduling',
        notificationColor: '#FAF614',
        url: '/scheduling'
    },
    {
        imgSrc: './assets/icons/services/tracking.svg',
        text: 'Boat Tracking',
        notificationColor: '#2EFAD5',
        url: '/tracking'
    },
    {
        imgSrc: './assets/icons/services/checklist.svg',
        text: 'Checklists',
        notificationColor: '#CCFF00',
        url: '/checklist'
    },
    {
        imgSrc: './assets/icons/services/expense.svg',
        text: 'Expenses',
        notificationColor: '#0095E9',
        url: '/expense'
    },
]

// <--------- Status List --------->
export const statusList = [
    {
        status: 'PENDING',
        text: 'Pending',
        bgColor: '#F0330A'
    },
    {
        status: 'IN_PROCESS',
        text: 'In Process',
        bgColor: '#D7AA0B'
    },
    {
        status: 'COMPLETED',
        text: 'Completed',
        bgColor: '#23AA0D'
    }
]

// <--------- Download/Open App --------->
export const downloadOrOpenApp = () => {
    // if (token)
    //     window.location.href = `https://bma.ifalcon.net/app/Splash?emailConfirmationToken=${token}`;
    // else

    window.location.href = `https://bma.ifalcon.net/app/Splash`;
}

// <--------- Get Current Date --------->
export function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const currentDate = `${year}-${month}-${day}`;
    return currentDate;
}

// <--------- To Check Device Type--------->
export function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android|blackberry|windows phone|iemobile|wpdesktop/.test(userAgent);
    return isMobileDevice;
}


// <--------- Deive Id --------->
export function getDeviceId() {
    let id = store.get(DEVICE_ID);
    if (!id) {
        id = uuidv4();
        store.set(DEVICE_ID, id);
        return id;
    }
    return id;
}