import React, { useState } from 'react';
import Table from '../../Table';
import { services } from '../../../constant/constant';

export default function HomeWithBanner() {
  return (
    <div className='mx-[3vw] sm:mx-[2vw] md:mx-[2vw] lg:mx-[6vw] xl:mx-[10vw] max-w-[1800px]'>
      <div className='flex my-[5%]'>
        <div className='w-1/4'>
        <img className='rounded-xl aspect-video w-full h-full object-cover' src='./assets/images/boat.jpg' />
        </div>
        <div className='sm:ml-6 md:ml-8 flex-1 flex flex-col justify-between'>
          <div className='flex'>
            <div className='font-inter text-2xl font-semibold mr-4 dark:text-[#B3B2B2]'>Endeavor <span className='text-[#B3B2B2] dark:text-white'>SP-215</span></div>
            <div className='bg-[#F0780A1A] flex items-center rounded-2xl px-6 text-[13px] text-[#F0780A]'><span className='h-4 w-4 flex justify-center items-center bg-[#F0780A] rounded-full text-[10px] text-white mr-2'>1</span><span className='font-inter'>Repair Pending!</span></div>
          </div>

          <div className='grid grid-cols-6 sm:gap-4 xl:gap-5 dark:text-white h-full sm:mt-[3%] xl:mt-[6%]'>
            {
              services.map((item, index) => {
                return (
                  <div key={index} className='rounded-lg relative shadow-custom h-full  flex flex-col justify-center px-3 dark:bg-[#253444] cursor-pointer'>
                    <img className='h-9 w-9' src={item.imgSrc} />
                    <span className='text-[13px] font-inter mt-1 sm:hidden xl:block'>{item.text}</span>
                    <span style={{background: item.notificationColor}} className='absolute top-1 right-1 h-4 w-4 flex justify-center items-center rounded-full text-[10px] text-white'>1</span>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
      <Table />
    </div>
  )
}
