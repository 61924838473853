import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { userLoginApi } from '../../rest/AuthApi';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function AddNewLogbookEntryForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [repairStatusValue, setRepairStatusValue] = useState('Pending');
    const [boatStatusValue, setBoatStatusValue] = useState('Repair Pending');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();


    // <--------- Form submit function --------->
    async function onSubmit(data) {
        console.log(data);
        // setIsLoading(true);
        // const result = await userLoginApi(data);
        // if (result?.status === 203) {
        //     enqueueSnackbar('Confirmation email sent', { variant: 'success' });
        // }
        // else if (result?.status === 400) {
        //     enqueueSnackbar('Invalid credentials', { variant: 'error' });
        // }
        // else
        //     enqueueSnackbar('Something went wrong', { variant: 'error' });
        // setIsLoading(false);
    }
    return (
        <form className='mt-5 w-full' onSubmit={handleSubmit(onSubmit)}>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Trip Date</div>
            <div>
                <label>
                    <input
                        type='date'
                        className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                        {...register("tripDate", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.tripDate)}
                    />
                </label>
                {
                    errors.tripDate && <div className='text-[11px] text-red-400 mt-1'>{errors.tripDate?.message}</div>
                }
            </div>

            <div className='flex justify-evenly mt-5'>
                <div className='flex flex-col items-center'>
                    <div className='dark:text-white text-[13px] mb-1'>Crew on board</div>
                    <input
                        placeholder='3'
                        type='number'
                        className='bg-white rounded-lg text-sm text-center px-2  outline-none border dark:border-0 border-gray-300 w-12 h-10'
                        {...register("numberOfCrews", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.numberOfCrews)}
                    />
                </div>

                <div className='flex flex-col items-center'>
                    <div className='dark:text-white text-[13px] mb-1'>Number of Passengers</div>
                    <input
                        placeholder='10'
                        type='number'
                        className='bg-white rounded-lg text-sm text-center px-2 outline-none border dark:border-0 border-gray-300 w-12 h-10'
                        {...register("numberOfPassengers", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.numberOfPassengers)}
                    />
                </div>

            </div>

            {
                errors.fault && <div className='text-[11px] text-red-400 mt-1'>{errors.fault?.message}</div>
            }

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Trip Origin</div>
            <div>
                <input
                    placeholder='Trip Origin'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("tripOrigin", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.tripOrigin)}
                />
                {
                    errors.tripOrigin && <div className='text-[11px] text-red-400 mt-1'>{errors.tripOrigin?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Trip Destination</div>
            <div>
                <input
                    placeholder='Trip Destination'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("tripDestination", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.tripDestination)}
                />
                {
                    errors.tripDestination && <div className='text-[11px] text-red-400 mt-1'>{errors.tripDestination?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Departure Time</div>
            <div>
                <input
                    placeholder='Departure Time'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("departureTime", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.departureTime)}
                />
                {
                    errors.departureTime && <div className='text-[11px] text-red-400 mt-1'>{errors.departureTime?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Arrival Time</div>
            <div>
                <input
                    placeholder='Arrival Time'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("arrivalTime", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.arrivalTime)}
                />
                {
                    errors.arrivalTime && <div className='text-[11px] text-red-400 mt-1'>{errors.arrivalTime?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Trip Name (optional)</div>
            <div>
                <input
                    placeholder='Trip Name'
                    className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10'
                    {...register("tripName", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.tripName)}
                />
                {
                    errors.tripName && <div className='text-[11px] text-red-400 mt-1'>{errors.tripName?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Upload Media</div>
            <div>
                <label>
                    <input
                        type='file'
                        hidden
                        {...register("media", {
                            required: "Field required*"
                        })}
                        error={Boolean(errors.media)}
                    />
                    <div className='flex justify-center items-center bg-white rounded-lg text-sm w-full outline-none border dark:border-0 border-gray-300 h-10 font-medium'>
                        <PlusIcon className='h-5 w-5 mr-1' strokeWidth={2} />
                        <span>Upload media</span>
                    </div>
                </label>
                {
                    errors.media && <div className='text-[11px] text-red-400 mt-1'>{errors.media?.message}</div>
                }
            </div>

            <div className='dark:text-white text-custom-bg  font-inter mb-1 mt-5 text-[13px]'>Notes</div>
            <div>
                <textarea
                    rows={5}
                    placeholder='Notes'
                    className='flex justify-center items-center bg-white rounded-lg px-4 py-3 text-sm w-full outline-none border dark:border-0 border-gray-300'
                    {...register("notes", {
                        required: "Field required*"
                    })}
                    error={Boolean(errors.notes)}
                />
                {
                    errors.notes && <div className='text-[11px] text-red-400 mt-1'>{errors.notes?.message}</div>
                }
            </div>

            <Button className='text-white font-medium font-inter tracking-wide h-10 bg-[#0E9CEC] hover:bg-hover-button text-sm rounded-lg w-full mt-6 mb-16 shadow-lg capitalize' type='submit'>{isLoading ? <CircularProgress sx={{ color: 'white' }} size={26} /> : "Add repair"}</Button>
        </form>
    )
}
