import store from 'store';
import { authInstance } from "./AxiosInstance";
import { DEVICE_ID, SESSION_ID } from "../constant/constant";

// <--------- User Registration --------->
export const userRegistrationApi = async (body) => {
    try {
        const result = await authInstance.post('/register', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- User Login --------->
export const userLoginApi = async (body) => {
    try {
        const result = await authInstance.post('/login', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Email Verification --------->
export const emailVerificationApi = async (token) => {
    try {
        const result = await authInstance.get(`/login/confirmation?token=${token}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Login Check --------->
export const checkLoginApi = async () => {
    try {
        let id = store.get(DEVICE_ID);
        if (id) {
            const result = await authInstance.get(`/login/check?deviceId=${id}`);
            return result;
        }

    } catch (error) {
        return error?.response;
    }
}

// <--------- Logout --------->
export const logoutApi = async () => {
    try {
        let sessionId = store.get(SESSION_ID);
        const result = await authInstance.get(`/logout?sessionId=${sessionId}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}