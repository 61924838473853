import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import { getAllRepairsApi } from '../../rest/ServiceApi';
import { enqueueSnackbar } from 'notistack';
import { statusList } from '../../constant/constant';
import Loader from '../Loader';

export default function Repair() {
  const navigate = useNavigate();
  const [allRepairs, setAllRepairs] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  // <--------- Get All Repairs --------->
  async function getAllRepair() {
    setIsLoading(true);
    const result = await getAllRepairsApi();
    if (result?.status === 200) {
      setAllRepairs(result?.data?.results)
    }
    // else if (result?.status === 400) {
    //   enqueueSnackbar('Invalid credentials', { variant: 'error' });
    // }
    else
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    setIsLoading(false);
  }

  useEffect(() => {
    getAllRepair();
  }, [])


  function getStatusObject(status) {
    const repairObject = statusList.find(option => option.status === status);
    return repairObject;
  }

  const allRepairJsx = (
    allRepairs?.map((item, index) => {
      return (
        <div key={index} className='flex min-h-28 rounded-lg dark:text-white dark:bg-[#253444] bg-[#EEEEEE] overflow-hidden pr-4 mt-3'>
          <div className='w-[0.4rem] mr-3 bg-repair'></div>
          <div className='flex flex-col justify-between w-full'>
            <div className='w-full'>
              <div className='flex justify-between text-[14px] font-medium mt-2 mb-1'>
                <span>{item?.equipment}</span>
                <span>24JAN24</span>
              </div>
              <p className='text-[12px] dark:font-light'>{item?.fault}</p>
            </div>
            <div className='flex justify-end'>
              <button style={{ background: getStatusObject(item?.status)?.bgColor }} className='rounded-xl text-[13px] py-[0.2rem] font-medium w-24 mb-2 text-white'>
                {getStatusObject(item?.status)?.text}
              </button>
            </div>
          </div>
        </div>
      )
    })
  )

  return (
    <div className='min-h-screen dark:bg-custom-bg pb-4'>
      <Navbar imgIconSrc={'/./assets/icons/services/repair.svg'} text={'Repairs'} />
      <div className='mx-4 pt-16'>
        <div className='dark:text-white font-inter text-lg font-bold text-center mt-1 mb-4'>Endeavor</div>
        <div className='bg-[#FF762D] rounded-lg flex justify-center items-center h-8 text-white font-inter font-medium text-[13px]'>
          Repair Pending
        </div>

        <div className='flex justify-end mt-3'>
          <Button className='flex items-center bg-custom-blue rounded-md px-2 py-1' onClick={() => navigate('/addRepair')}>
            <PlusIcon className='h-4 w-4 text-white' strokeWidth={2} />
            <div className='text-white ml-1 font-inter text-[13px] normal-case'>Add new repair</div>
          </Button>
        </div>

        {isLoading ? <div className='mt-12'><Loader /></div> : allRepairJsx}

      </div>
    </div>
  )
}
